<template>
<!--  <EditCompanyMenu />-->
  <CompanyInfo class="mt-10 lg:mx-56 sm:mx-10 mx-4"/>
</template>

<script>
// import EditCompanyMenu from "@/components/companies/editCompany/EditCompanyMenu";
import CompanyInfo from "@/components/companies/editCompany/CompanyInfo";

export default {
  name: 'addCompany',
  components: {CompanyInfo },

  mounted() {
    this.$store.commit('resetDynamicTableState')
  }
}
</script>